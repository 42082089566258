import React, { useState, useEffect } from 'react';
import { useUserAuth } from "../context/UserAuthContext";
import { Link, useNavigate, useLocation , useParams } from 'react-router-dom';
import left from '../images/Left.png';
import scroll from '../images/scroll.png';
import scrollrev from '../images/scrollRev.png';
import { Tabs } from 'antd';
import { Overview } from './components/Overview';
import { Excercise } from './components/Excercise';
import FollowUpSession from './components/FollowUpSession';
import { ProgramRenewal } from './components/ProgramRenewal';
import { ConfigProvider, Typography } from "antd";
import { analytics } from "../firebase/index";
import { logEvent } from "firebase/analytics";
import NoInternetPopup from './components/NoInternetPopup';
import plan from '../images/myplanpic.png'

export const PatientProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { patient } = location.state || {};
  const { user, ID, getPatientsDetails, patientActiveProgram, getPhysioPatients, getAssessmentData } = useUserAuth();
  const { patientid,patientname,patientnumber } = useParams();
  // console.log("patient id", patientid);
  const decodedPatientId = decodeURIComponent(patientid);
  // console.log("decoded ",decodedPatientId)
  const [patientData, setPatientData] = useState([]);
  const [patientName, setPatientName] = useState('');
  const [patientNumber, setPatientNumber] = useState('');
  const [bandColor, setBandColor] = useState('');
  const [sideSelected, setSideSelected] = useState('');
  const [activeProgram, setActiveProgram] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [patientId, setPatientID] = useState('');
  const [programID, setProgramID] = useState('');
  const [duration, setDuration] = useState('');
  const [currentday, setCurrentDay] = useState(0);
  const [Flag, setFlag] = useState(false);
  const [initialPatientData, setInitialPatientData] = useState([]);
  const [patientsData, setPatientsData] = useState([]);
  const [requested, setRequested] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedProgramData, setSelectedProgramData] = useState(null);
  const [selectedDot, setSelectedDot] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [Tab, setTab] = useState(true);


  const onChange = (key) => {
    console.log("the tab is clicked or changed",key);
    logEvent(analytics, 'py_patient_profile_page_tabclicked', {
      Date: new Date().toLocaleString(),
      ID,
      "patientID": patientId,
      "TabClicked": key,
      "py_log" : "Tab clicked 1 for Overview , 2 for Exercise"
    }); 
  };

  const items = [
    {
      key: '1',
      label: 'Overview',
      children: <Overview patientId={patientId} programId={selectedProgram} side={sideSelected} programdata = {selectedProgramData}/>,
    },
    {
      key: '2',
      label: 'Excercise',
      children: <Excercise patientId={patientId} programId={selectedProgram} duration={duration} programdata = {selectedProgramData}/>,
    },
    
  ];

  const customTabStyle = {
    color: '#6e7382',
    fontFamily: '"Rajdhani-Medium", Helvetica',
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: 'normal',
    width: '173px',
  };
  
  useEffect(() => {
    // Scroll to the top of the page when it loads
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs once when the component mounts


  // fetching patient data 
  // fetching Patient profile from Physio collection  data (programs)  this can be used in future   
  useEffect(() => {
    const fetchUserData = async () => {
      // const data = await getPhysioPatients();
      const data = JSON.parse(localStorage.getItem('Data'));
      console.log("this is checkpoint 1 local ------------------",data);
      const filteredData = data.filter(item => item.Patient_idFP === patientid);
      console.log("this is checkpoint 2 local------------------",filteredData);
      setInitialPatientData(filteredData[0]); 
      if(filteredData[0].length!=0){
        console.log("inside iffffffffffffffffff")
        setBandColor(filteredData[0].AllData?.PatientColorFP?.stringValue || '');
        setSideSelected(filteredData[0].AllData?.PatientSideFP?.stringValue || '');
        setRequested(filteredData[0].ConsultationRequested);
        console.log("the value of request ",requested,filteredData[0].ConsultationRequested);
      }else{
        const data = await getPhysioPatients();
        console.log("this is checkpoint 1 firebase------------------",data);
        const filteredData = data.filter(item => item.Patient_idFP === patientid);
        console.log("this is checkpoint 2 firebase------------------",filteredData);
        setBandColor(filteredData[0].AllData.PatientColorFP.stringValue);
        setSideSelected(filteredData[0].AllData.PatientSideFP.stringValue);
        setRequested(filteredData[0].ConsultationRequested);
        console.log("the value of request ",requested,filteredData[0].ConsultationRequested);
      }
      
  };
  console.log("fetching patient data in profile page  ",initialPatientData);

    fetchUserData();
  }, [patientid]);



  // fetching profile data
  useEffect(() => {
    const fetchPatientData = async () => {
      // console.log('Fetching Patient Data ...');
      console.log("patients  ",patient);  
      const Data = await getPatientsDetails(decodedPatientId);
      setPatientData(Data);
      console.log("patient data in front end",Data);
      setPatientName((Data.PatientName||Data.patient_name).stringValue); 
      setPatientID(decodedPatientId);
      setPatientNumber(patientnumber);
      
      // setFlag(Data.ispaymentdone.booleanValue);
      // console.log("this what we need ----------------------------",Flag); 
      console.log("checking patient detail in profile page ",Data)
      // console.log("checking ",patientData.currentDay)
      if(patientData.currentDay)
      {
        setCurrentDay(patientData.currentDay?.integerValue || 0);
      }
      else{
        setCurrentDay(0);
      }
      // setBandColor(Data.BandColour?.arrayValue?.values[0]?.stringValue||'');
      // setSideSelected(Data.sideSelected?.stringValue||'');
      logEvent(analytics, 'py_patientprofile_patient_details', {
        Date: new Date().toLocaleString(),
        ID,
        "patientID": patientId,
        "pyPatientData": JSON.stringify(Data),
        "py_log" : "fetching py patient data for patient Profile Page"
      }); 
    };

  fetchPatientData();
  }, []);

  // fetching patient program details 
  useEffect(() => {
    const fetchProgram = async () => {
      setTab(false);
      console.log("Fetching Patient Program ... ",decodedPatientId);
      const programs = await patientActiveProgram(decodedPatientId);
      console.log("program ------------------------- ",programs)
      setActiveProgram(programs);
      setIsLoading(false);
      if(programs.length !== 0){
        setProgramID(programs[0].ID);
        setDuration(programs[0].duration);
        setSideSelected(programs[0].sideAssigned);
        setBandColor(programs[0].color);
        if(programs[0].enddate){
          setFlag(true);
        }
        setSelectedProgram(programs[0].ID);
        setSelectedProgramData(programs[0]);
      }
      setTab(true);
      console.log("Program ID ",programID);
      console.log("Duration of program ",duration);
      console.log("patient program ",activeProgram);
     
    };
    fetchProgram(); 
  }, []);

  useEffect(() => {
    // Re-render the Overview component after a delay to ensure current_day is updated
    // const timeout = setTimeout(() => {
      setCurrentDay(patientData.currentDay?.integerValue || 0);
    console.log("this is selected program ", selectedProgram);
    setActiveTabKey("1");

    // }, 3000); // Adjust the delay as needed

    // return () => clearTimeout(timeout); // Clean up the timeout on unmount
  }, [patientData, activeProgram, selectedProgram]);

  const handleScroll = (e) => {
    const totalTabs = activeProgram.length;
    const scrollPercentage = (e.target.scrollLeft / e.target.scrollWidth) * 100;
    const selectedDot = Math.round((scrollPercentage / 100) * (totalTabs ));
    setSelectedDot(selectedDot);
  };


  return (
    <div className='profile-page' style={{paddingTop:"2%", backgroundColor:"#f9fafe", height:"100vh"}}>
      <br />
      <NoInternetPopup />
      <div className='pprofile-header'>
        <img 
          className='back-image1'
          src={left}
          alt="back"
          onClick={() => navigate('/home')}
          style={{marginLeft:"1%"}}
        />
        &nbsp; &nbsp;
        <div className='pprofile-pname'>
          {patientname}
        </div>
        <br />
      </div>

      {/* <ProgramRenewal patientId = {patientId}/> */}
      <br />
      {/* <FollowUpSession patientName = {patientname} patientNumber = {patientNumber} patientId = {patientId} requested = {requested}/> */}
      <br />
      {activeProgram.length > 0 && !isLoading ? (
        <div className='program-block'>
          <div className='program-tab' onScroll={handleScroll}>
            {activeProgram.map((program, index) => (
              <div
                key={program.ID}
                className={`program ${activeProgram.length > 1 && selectedProgram === program.ID ? 'selected-program' : ''}`}
                style={{ display: 'inline-block', marginRight: '10px', display:"flex", }}
                onClick={() => {
                  setSelectedProgram(program.ID);
                  setSelectedProgramData(program);
                }}
              >
                <div>
                  {(() => {
                    const exerciseName = program.programName.toLowerCase();
                    const TemplateID = program.profile._document.data.value.mapValue.fields.Template_id.stringValue.toLowerCase();

                    const getRandomThumbnail = (joint) => {
                      const cycleIndex = 1;
                      return `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExcercisePlanImages%2F${joint}Thumbnail${cycleIndex}.png?alt=media&token=c94ba79a-26ce-4efb-9f4f-70865845ef68`;
                    };
                    
                    const jointImages = {
                      knee: getRandomThumbnail('Knee'),
                      hip: getRandomThumbnail('Hip'),
                      shoulder: getRandomThumbnail('Shoulder'),
                      neck: getRandomThumbnail('Neck'),
                      back: getRandomThumbnail('Back'),
                      ankle: getRandomThumbnail('Ankle'),
                    };
                    
                    let joint = Object.keys(jointImages).find(joint => exerciseName.includes(joint));
                    joint = Object.keys(jointImages).find(joint => TemplateID.includes(joint));
                    
                    
                    const imgSrc = joint ? jointImages[joint] : plan;

                    return <img src={imgSrc} alt="" style={{ width: "116px", paddingRight: "5%", borderRadius:"10px" }} />;
                  })()}
                </div>
                <div>
                  <div style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "left",
                    width:"60vw"
                  }}>
                    Program:&nbsp;<div className='program-name'>{program.programName}</div>
                  </div>

                  <div className='pprofile-desc'>
                    <div>
                      Side: {program.sideAssigned}
                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "left"
                    }}>
                      Theraband:&nbsp;<p style={{ color: program.color, marginBottom:"0" }}>{program.color}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
            <div className='dots-container'>
              {activeProgram.length>1 && activeProgram.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${selectedDot === index ? 'selected-dot' : ''}`}
                  onClick={() => {
                    setSelectedDot(index);
                    // Scroll to the corresponding tab
                    const programTab = document.querySelector('.program-tab');
                    if (programTab) {
                      programTab.scrollTo({
                        left: (programTab.scrollWidth / (activeProgram.length - 1)) * index,
                        behavior: 'smooth',
                      });
                    }
                  }}
                ></div>
              ))}
            </div>
        </div>

        ):(
        <>
        <div className='addAIPblock'>
          <div>
            Assign an AI program
          </div>
        </div>
        <div className='addAIPblock1'>
          <div style={{width:"60%"}}>
            Personalized Path to Pain Relief and Recovery
          </div>
          <div className='addAIPblock-text' style={{padding:"3% 8%", height:"10%"}}>
          <Link to={`/addaiprogram/${patientid}`} style={{textDecoration:"none", color:"white"}}>ADD</Link>
          </div>
        </div>
      </>
      )}

     
      

      {selectedProgram && (
        <div className="program-details">
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "Rajdhani",
                color: "#2d2f35",
                fontWeight: "700",
              }
            }}
          >
            {selectedProgramData.enddate ? (
              <>
                <div className='enddate_banner'>
                  End - {selectedProgramData.enddate.substring(0, 11)}
                </div>
                {Tab && (
                  <>
                    <Typography.Text></Typography.Text> 
                    <Tabs defaultActiveKey='1' items={items} onChange={onChange} />
                  </>
                )}
                
              </>
            ) : (
              <>
                <div style={{ fontWeight: "600", backgroundColor: "#FFF6F6", color: "#C20505", padding: "5%", borderRadius: "10px" }}>
                  <i>The program has been assigned, the patient has not subscribed yet. </i>
                </div>
              </>
            )}
          </ConfigProvider>
        </div>
      )}

      {isLoading && (
        <div className="spinner-container">
          <div className="spinner-grow" role="status">
          </div>
        </div>
      )}

      
    </div>


    
  )
}
