import React, { useState, useEffect } from 'react'
import left from '../images/Left.png'
import { useParams, useNavigate, Link } from 'react-router-dom';
import planpic from '../images/myplanpic.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useUserAuth } from "../context/UserAuthContext";
import { AddPatientSuccessPopUp } from './components/AddPatientSuccessPopUp';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { analytics } from "../firebase/index";
import { logEvent } from "firebase/analytics";  
import NoInternetPopup from './components/NoInternetPopup';
import Modal from 'react-modal';
import error from '../images/error.png';
import ok from '../images/Blue OK.png';
import cross from '../images/Close.png';
import moment, { duration } from 'moment';
import ReactCountryFlag from 'react-country-flag';
import Dropdown from 'react-bootstrap/Dropdown'; // Import Dropdown
import countries from '../utils/countries'
import Form from 'react-bootstrap/Form';
import spinner from '../images/loaderr.gif'; // Placeholder spinner image


export const AddPatient = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [patientID, setPatientID] = useState('');
  const [phoneNumber1, setPhoneNumber1] = useState('');
  const [reason, setReason] = useState('');
  const [color, setColor] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSide, setSelectedSide] = useState('');
  const [programs, setPrograms] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [Templateid, setTemplateid] = useState('');
  const [FBTemplateid, setFBTemplateid] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State to control the visibility of the success modal
  const [isLoading, setIsLoading] = useState(false);
  const [PatientData, setPatientData] = useState({});
  const [showAIProgram, setShowAIProgram] = useState(2);
  const [allDetailsFilled, setAllDetailsFilled] = useState(false); // New state variable
  const [showAIProgramModel, setShowAIProgramModel] = useState(false);
  const [customFlag, setCustomFlag] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false); 

  const [nameError, setNameError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [reasonError, setReasonError] = useState('');
  const [colorError, setColorError] = useState('');
  const [selectedSideError, setSelectedSideError] = useState('');
  const [selectedPlanError, setSelectedPlanError] = useState('');
  const [prepaidProgram, setPrepaidProgram] = useState(false);
  const [startDateModel, setStartDateModel] = useState(false);
  const [physioName, setPhysioName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [countryName, setCountryName] = useState('India'); // Default country code
  const [loadedImages, setLoadedImages] = useState({});
  
  const [selectedDuration, setSelectedDuration] = useState('30 days'); // Default selection
  const [customDuration, setCustomDuration] = useState(30);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedJoint, setSelectedJoint] = useState(''); 
  const [jointSearchQuery, setJointSearchQuery] = useState(''); 
  const jointOptions = ['Knee', 'Hip', 'Shoulder', 'Neck', 'Back', 'Ankle'];

  const navigate = useNavigate();

  const { getAllPrograms, EncryptData11, DecryptData11, addPatientData, checkuser, ID, fetchPhysioData } = useUserAuth();


  // fetching lans data (programs)
  useEffect(() => {
    const fetchPrograms = async () => {
      console.log('Fetching programs data...');
      const programsData = await getAllPrograms();
      setPrograms(programsData);
    };

    fetchPrograms();
  }, []);

  useEffect(() => {
    const fetchPhysio = async () => {
      const physioData = await fetchPhysioData();
      console.log("this is physio Data ",physioData);
      setPhysioName(physioData.Name);
      if(physioData.AssignPrepaidProgram){
        setPrepaidProgram(physioData.AssignPrepaidProgram);
      }
      console.log("the value of variable ",prepaidProgram,physioData.AssignPrepaidProgram);
    };

    fetchPhysio();
  }, []);

  useEffect(() => {
    // Set country code based on user's location
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;
      const response = await fetch(`https://geocode.xyz/${latitude},${longitude}?json=1`);
      const data = await response.json();
      const userCountry = countries.find(country => country.name === data.country);
      console.log("this is the country", userCountry);
      if (userCountry) {
        setCountryName(userCountry.name);
      }
    });
  }, []);

  useEffect(() => {
    // This code block  will run whenever showAIProgram changes
    console.log('showAIProgram has changed:', showAIProgram);
    console.log("the value of prepaid program",prepaidProgram);
  }, [showAIProgram]);
  
  
  const boxColors = ['Yellow', 'Red', 'Green', 'Blue', 'No Band']; 
  const boxColors1 = ['#e1d144', '#ff4242', '#006d2e', '#05328d', '#ffffff']; 

  const handleStartDateChange = (e) => {
    const date = e.target.value;
    console.log("the value of start date ",date); 
    setStartDate(date);
  }


  const handleBoxClick = (selectedColor) => {   //color selection handled 
    setColor(selectedColor);
    setSelectedColor(selectedColor);
    setColorError('');
  };

  const handleOptionClick = (option) => {      // Side selection handled 
    setSelectedSide(option);
    setSelectedSideError('');
  };

  function extractJointName(moduleID) {
    const jointNames = ["Hip", "Knee", "Shoulder", "Ankle", "Back", "Neck"];
    if (moduleID === "b2bHipandKnee0002") {
      return "Hip";
    }
    if (moduleID === "b2bHipandKnee0001") {
      return "Knee";
    }
    return jointNames.find(joint => moduleID.toLowerCase().includes(joint.toLowerCase())) || "";
  }
  
  const filteredPrograms = programs.filter((program) => {
    const exerciseName = program._document.data.value.mapValue.fields.Program_name.stringValue.toLowerCase();
    const templateID = program._document.data.value.mapValue.fields.Template_id.stringValue.toLowerCase();

    // Match against selected joint
    let matchingJoint = extractJointName(templateID);

    // Match against search query
    const matchesSearchQuery = exerciseName.includes(jointSearchQuery.toLowerCase());

    // Apply joint filter and search query filter
    return (!selectedJoint || matchingJoint === selectedJoint) && matchesSearchQuery;
  });

  const handleJointClick = (joint) => {
    setSelectedJoint(joint === selectedJoint ? '' : joint); // Toggle selection
  };


  const handleJointSearchChange = (e) => {
    setJointSearchQuery(e.target.value);
  };

  const handlePlanClick = async(programName, programDetials) => {   // Selected Program for the user 
    
    console.log("this is program detials user clicked ", programName,programDetials);
    if(programDetials._key.path.segments[5] === "CustomPrograms"){
      setCustomFlag(true);
    }else{
      setCustomFlag(false);
    }
    setSelectedPlan(programName.Program_name.stringValue);
    setFBTemplateid(programName.Template_id.stringValue);
    const userData = await fetchPhysioData();
    console.log("this is user data ",userData.PhysioID+(parseInt(userData.AssignedProgramCount)+1));
    console.log("selected program :",programName.Program_name.stringValue);
    setTemplateid(userData.PhysioID+(parseInt(userData.AssignedProgramCount)+1));
    console.log("template id :",FBTemplateid);
    console.log("Custom Flag :",customFlag);
    setSelectedPlanError('');
  };

  const handleCountryCodeChange = (name) => {
    // console.log("country selected ",name, countries.find(country => country.code === countryName)?.code)
    setCountryName(name);
  };



  const handleImageClick = () => {
    navigate('/home');
  };

  const handlePhoneNumberChange = async (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
  
    const selectedCountry = countries.find(country => country.name === countryName);
    const phoneLength = selectedCountry.phoneLength; // Default to 10 if country is not found
  
    if (numericValue.length === phoneLength) {
      setPhoneNumberError('');
      setPhoneNumber1(numericValue);
      console.log("this is a number");
      const Data = await checkuser(countries.find(country => country.name === countryName)?.code,numericValue);
      console.log("number ", Data);
      setShowAIProgram(Data.returnvalue);
      setPatientID(Data.id);
      setPhoneNumber(numericValue);
    } else {
      setPhoneNumberError('Enter Correct Phone Number');
      console.log("this is not a number");
      setPhoneNumber(null);
    }
    setPhoneNumber1(numericValue);
  };
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCountries = countries.filter((country) => 
    country.name.toLowerCase().includes(searchQuery.toLowerCase()) || country.code.includes(searchQuery)
  );

  const handleImageLoad = (exerciseName) => {
    setLoadedImages((prev) => ({ ...prev, [exerciseName]: true }));
  };


  const handleNameChange = async(e) => {
    const inputValue = e.target.value;
    setName(inputValue);
    setNameError('');
  };

  const handleReasonChange = async(e) => {
    const inputValue = e.target.value;
    setReason(inputValue);
    setReasonError('');
  };

  useEffect(() => {
    if (name && phoneNumber && reason && color && selectedSide && selectedPlan) {
      setAllDetailsFilled(true);
      console.log("all detials are filled ");

    }else{
      console.log("all detials are not filled ");
      setAllDetailsFilled(false);
    }
  }, [name,phoneNumber,reason,color,selectedSide,selectedPlan]);

  const handleDurationChange = (value) => {
    console.log("this is duration ", value);
  
    if (value === 'Other') {
      setSelectedDuration('Other');
    } else {
      setSelectedDuration(value);
      setCustomDuration(value.substring(0,2));
    }
  };
  

  const handleCustomDurationChange = (e) => {
    setCustomDuration(e.target.value);
    console.log("Duration ", e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("checkpoint ")
    let k = 0;
    if (!name) {
      setNameError('Name is required');
      k=1;
    } else {
      setNameError('');
    }
  
    if (!phoneNumber) {
      setPhoneNumberError('Phone number is required');
      k=1;
    } else {
      setPhoneNumberError('');
    }
  
    if (!reason) {
      setReasonError('Reason is required');
      k=1;
    } else {
      setReasonError('');
    }
  
    if (!color) {
      setColorError('Threadband color is required');
      k=1;
    } else {
      setColorError('');
    }
  
    if (!selectedSide) {
      setSelectedSideError('Side is required');
      k=1;
    } else {
      setSelectedSideError('');
    }
  
    if (!selectedPlan) {
      setSelectedPlanError('Module is required');
      k=1;
    } else {
      setSelectedPlanError('');
    }
    if(k===1){
      return;
    }
    let date;
    if (prepaidProgram) {
      if (startDate === '') {
          // Set startDate to today's date
          const today = new Date();
          date = today.toISOString().split('T')[0];
          setStartDate(date);
          // Optionally, you can remove the setStartDateModel(true) and setStartDateModel(false) lines if not needed.
          // setStartDateModel(true);
          // setStartDateModel(false);
      } else {
          setStartDateModel(false);
      }
    }

    // if(prepaidProgram){   // for custom model to show up with start date 
    //   console.log("the value of start Date ",startDate);
    //   if(startDate===''){
    //     setStartDateModel(true);
    //     return;
    //   }else{
    //     setStartDateModel(false);
    //   }
    //   // return;
    // }

    console.log("the value of start Date ", startDate);


    console.log("encrytpted number",startDate)
    if(showAIProgram===1){
      setShowAIProgramModel(true);
      logEvent(analytics, 'py_added_B2C_patient', {
        Date: new Date().toLocaleString(),
        py_id: ID,
        patientData: JSON.stringify(phoneNumber),
        log: "Add patient button Clicked on Add Patient Page for B2C patients"
      });
      return;

    }

    const patientData = {
      PatientNameFP: name,
      PatientMobileFP: phoneNumber,
      PatientTreatmentFP: reason,
      PatientColorFP: color,
      PatientSideFP: selectedSide,
      PatientAssignedProgramNameFP: selectedPlan,
      Template_id: Templateid,
      FBTemplateid: FBTemplateid,
      customFlag: customFlag,
      CountryName: countryName,
      CountryCode: countries.find(country => country.name === countryName)?.code,
      PatientID: patientID,
      duration: customDuration,
      updateProfile: showAIProgram,
      // Other patient data...
    };
    logEvent(analytics, 'py_add_patient', {
      Date: new Date().toLocaleString(),
      py_id: ID,
      patientData: JSON.stringify(patientData),
      log: "Add patient button Clicked on Add Patient Page"
    });
    

    
    setPatientData(patientData);
    console.log("patient Data ",patientData);
    try {
      // Call the function to add encrypted patient data
      console.log("Data Adding ...")
      setIsLoading(true);
      const result = await addPatientData(patientData, prepaidProgram, date);
        console.log("data added successfully");
        if (result === 1) {
          setShowSuccessModal(true);
        } else {
          setShowErrorModal(true);
        }
      
      } catch (error) {
        console.log("Error adding encrypted patient data:", error);
        logEvent(analytics, 'py_add_patient_error', {
          Date: new Date().toLocaleString(),
          "py_id": ID,
          patientData: JSON.stringify(patientData),
          error: JSON.stringify(error),
        });
        // Handle the error, e.g., show an error message to the user
    } finally {
      setIsLoading(false);
    }

    
  };

  return (
    <div className='add-patient-page' style={{marginTop:"1%"}}>
      <br />
      <div className=''>
            <img 
                className='AP-back-image'
                src={left}
                alt="back"
                onClick={handleImageClick}
            />
            &nbsp; &nbsp;
            <span className='add-patient-text'>Add Patient</span>
        </div>
        <br />
        <form onSubmit={handleSubmit}>
          <div className='APpage-text'>
            Name
          </div>
          <input
            className='APpage-input'
            type="text"
            id="name"
            value={name}
            onChange={handleNameChange}
            // required
          />
          { nameError && <div className="error-message">{nameError}</div> }

          <div className='APpage-text'>
            <br />
            Phone Number
          </div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <Dropdown onSelect={handleCountryCodeChange} style={{ marginRight:'10px',fontWeight: '650', fontSize: '120%', borderColor:'#bed0fb', height:"40px"}}>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <ReactCountryFlag countryCode={countries.find(country => country.name === countryName)?.countryCode} svg style={{ marginLeft: '10px' }} /> 
                &nbsp; {countries.find(country => country.name === countryName)?.code}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-animated">
                <Form.Control 
                  type="text" 
                  placeholder="Search country code or name" 
                  value={searchQuery} 
                  onChange={handleSearchChange} 
                  style={{ margin: '5%', width:"90%" }}
                />
                {filteredCountries.map((country, index) => (
                  <>
                    <Dropdown.Item key={country.name} eventKey={country.name}>
                      <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div style={{marginRight:"10px"}}>
                          <ReactCountryFlag countryCode={country.countryCode} svg/> {country.name} 
                        </div> 
                        <div>{country.code}</div>
                      </div>
                    </Dropdown.Item>
                    {index !== filteredCountries.length - 1 && <hr style={{ margin: '0 8px', border: 'none', borderBottom: '2px solid #ccc' }} />}
                  </>
                  
                ))}
              </Dropdown.Menu>
            </Dropdown>
              <input
                style={{
                  backgroundColor: '#ffffff',
                  border: '1px solid',
                  borderColor: '#bed0fb',
                  borderRadius: '10px',
                  height: '48px',
                  // width: '80%',
                  width: '100%',
                  padding: '3%',
                  marginRight:'10%',
                  fontSize:"110%"
                }}
                type="tel"
                maxLength="10"
                id="phoneNumber"
                value={phoneNumber1}
                onChange={handlePhoneNumberChange}
              />
          </div>
          
          { phoneNumberError && <div className="error-message">{phoneNumberError}</div> }

          <div className='APpage-text'>
            <br />
            Reason
          </div>
          <input
            className='APpage-input'
            type="text"
            id="phoneNumber"
            value={reason}
            onChange={handleReasonChange}
            // required
          />
          { reasonError && <div className="error-message">{reasonError}</div> }
        <br />

        <div className='APpage-text'>
            <br />
            Threadband
        </div>

        <div>
          { colorError && <div className="error-message">{colorError}</div> }
          <div style={{ display: 'flex', justifyContent: 'left' }} className='threadband-outerblock'>
            {boxColors1.map((boxColor, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: boxColor,
                  border: selectedColor === boxColors[index] 
                    ? '2px solid #0054ff'  
                    : boxColors[index] === 'No Band' 
                    ? '1px solid lightgray'
                    : 'none', 
                  boxShadow: selectedColor === boxColors[index] ? '0 0 10px #0054ff' : 'none',
                  width: boxColors[index] === 'No Band' ? '100px' : '', 
                }}
                className='threadband-div'
                onClick={() => handleBoxClick(boxColors[index])}
              >
                {boxColors[index] === 'No Band' && (
                  <div style={{ textAlign: 'center', color: 'gray', width:"100px", paddingTop:"2px" }}>No Band</div> // Label for No Color
                )}
              </div>
            ))}
          </div>
        </div>

        <div className='APpage-text'>
            <br />
            Side
        </div>

        <div>
          { selectedSideError && <div className="error-message">{selectedSideError}</div> }
          <div style={{ display: 'flex', justifyContent: 'left', overflowX:"auto"}} className='text-block-container'>
            <div
              
              className={`text-block ${selectedSide === 'Left' ? 'selectedbox' : ''} sideblock`}
              onClick={() => handleOptionClick('Left')}
            >
              Left
            </div>
            <div
              className={`text-block ${selectedSide === 'Right' ? 'selectedbox' : ''} sideblock`}
              onClick={() => handleOptionClick('Right')}
            >
              Right
            </div>
            <div
              className={`text-block ${selectedSide === 'Both' ? 'selectedbox' : ''} sideblock`}
              onClick={() => handleOptionClick('Both')}
            >
              Both
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className='APpage-text'>Duration:</label>
          <div className="text-block-container" style={{ display: 'flex', justifyContent: 'left', overflowX:"auto" }}>
            {['15 days', '30 days', '45 days', 'Other'].map((option) => (
              <div
                key={option}
                onClick={() => handleDurationChange(option)}
                className={`text-block ${selectedDuration === option ? 'selectedbox' : ''} sideblock`}
                style={{width:"20%", padding:"0"}}
              >
                {option}
              </div>
            ))}
            
          </div>
          {selectedDuration === 'Other' && (
              <input
                className='APpage-input'
                type="number"
                value={customDuration}
                onChange={handleCustomDurationChange}
                placeholder="Enter custom duration"
              />
            )}
        </div>

        
        <div>
          <div>
            <div style={{ display:"flex", justifyContent:"space-between", paddingRight:"5%", paddingBottom:"15px", paddingTop:"15px" }}>
              <div className='APpage-text' style={{ paddingBottom: "8px", width:"15%"}}>
                <br />
                Module
              </div>
              <div style={{ position: "relative", width: '30%' }}>
                <i className="fa fa-search" style={{ position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)", fontSize: "16px", color: "#aaa" }}></i>
                <input
                  className='APpage-input'
                  type="text"
                  placeholder="Search programs..."
                  value={jointSearchQuery}
                  onChange={handleJointSearchChange}
                  style={{ padding: '5px 5px 5px 30px', width: '100%', height:"35px", fontSize:"70%" }}
                />
              </div>
            </div>
              
           
            {selectedPlanError && <div className="error-message">{selectedPlanError}</div>}
            
            {/* Joint filter dropdown */}
            <div className="text-block-container" style={{ display: 'flex', justifyContent: 'left', marginBottom: '10px', width:"95%", overflowX:"auto" }}>
              {jointOptions.map(joint => (
                <div
                  key={joint}
                  onClick={() => handleJointClick(joint)}
                  className={`text-block ${selectedJoint === joint ? 'selectedbox' : ''} sideblock`}
                  style={{width:"200px"}}
                >
                  {joint}
                </div>
              ))}
            </div>

            {/* <input
              className='APpage-input'
              type="text"
              placeholder="Search programs..."
              value={jointSearchQuery}
              onChange={handleJointSearchChange}
              style={{ marginBottom: '10px', padding: '5px', width: '60%' }}
            /> */}

            {filteredPrograms.length > 0 ? (
              filteredPrograms.map((program, index) => {
                const exerciseName = program._document.data.value.mapValue.fields.Program_name.stringValue.toLowerCase();
                const templateID = program._document.data.value.mapValue.fields.Template_id.stringValue.toLowerCase();

                // Map joint names to their corresponding image URLs
                const getRandomThumbnail = (joint) => {
                  const cycleIndex = index % 3 + 1;
                  return `https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioExcercisePlanImages%2F${joint}Thumbnail${cycleIndex}.png?alt=media&token=c94ba79a-26ce-4efb-9f4f-70865845ef68`;
                };

                const jointImages = {
                  knee: getRandomThumbnail('Knee'),
                  hip: getRandomThumbnail('Hip'),
                  shoulder: getRandomThumbnail('Shoulder'),
                  neck: getRandomThumbnail('Neck'),
                  back: getRandomThumbnail('Back'),
                  ankle: getRandomThumbnail('Ankle'),
                };

                let matchingJoint = Object.keys(jointImages).find(joint => exerciseName.includes(joint));
                matchingJoint = Object.keys(jointImages).find(joint => templateID.includes(joint));

                // Set imgSrc to corresponding image URL or to default plan image
                const imgSrc = matchingJoint ? jointImages[matchingJoint] : planpic;

                return (
                  <div key={program._document.key.path.segments[6]}>
                    <div
                      className={`myplan-body1 ${selectedPlan === program._document.data.value.mapValue.fields.Program_name.stringValue ? 'selected' : 'randomclass'}`}
                      onClick={() => handlePlanClick(program._document.data.value.mapValue.fields, program)}
                    >
                      {/* <img className='myplan-image1' src={imgSrc} alt='My Plan' /> */}
                      <img
                        className='myplan-image1'
                        src={loadedImages[exerciseName] ? imgSrc : spinner}
                        alt={exerciseName}
                        onLoad={() => handleImageLoad(exerciseName)}
                      />
                      <div className='myplan-text21'>{program._document.data.value.mapValue.fields.Program_name.stringValue}</div>
                      {selectedPlan === program._document.data.value.mapValue.fields.Program_name.stringValue ? (
                        <img src={ok} style={{ height: "24px", marginTop: "20px", marginRight: "5px" }} />
                      ) : (
                        <div style={{ width: "24px" }}></div>
                      )}
                    </div>
                    <br />
                  </div>
                );
              })
            ) : (
              <div className='skeleton'>
                <Skeleton
                  sx={{ bgcolor: 'grey.900' }}
                  variant="rectangular"
                  className='home-loader'
                  height={70}
                /> <br />
              </div>
            )}
          </div>
        </div>
                
          <br /> 
          <br />
          <br />
        <button type="submit" className='AP-button2' onSubmit={handleSubmit} > 
          <div className='inner-AP-button'>
            <div className='AP-text'>ADD PATIENT</div>
          </div>
        </button>
        </form>

        {isLoading && (
          <div className="spinner-container">
            <div className="spinner-grow" role="status">
            </div>
          </div>
        )}
        <NoInternetPopup/>

        <Modal
          // Modal styling here
          isOpen={showAIProgramModel}
          onRequestClose={() => {
            setShowAIProgramModel(false);
            navigate('/home');
          }}
          style={{
            overlay: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgba(0, 0, 0, 0.5)', // Background overlay color
              transition: 'background 0.1s ease', 
              fontSize: "90%"
            },
            content: {
              position: 'relative',
              top: 'auto',
              left: 'auto',
              right: 'auto',
              bottom: 'auto',
              border: '1px solid', // Remove border
              borderColor: "#ac00004f",
              background: '#fff2f2', // Background color of the modal
              padding: '10px', // Adjust padding as needed
              width: '80%',
              borderRadius:"10px",
              color:"#9f0606",
              fontFamily:"Rajdhani",
              fontWeight:"500",
              fontSize:"110%",

            },
          }}

        >
          <div style={{
            display:"flex", 
            // justifyContent:"space-between",
            alignItems: "center",
          }}>
            <img 
              src={error} 
              style={{
                height:"35px",
                marginRight:"10px",
              }}  
            />
            <div>Unable to add a new program as the patient already has an existing plan</div>
          </div>
      </Modal>

      {/* <Modal
        isOpen={startDateModel}
        onRequestClose={() => {
          setStartDateModel(false);
        }}
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)', // Background overlay color
            transition: 'background 0.5s ease',
            zIndex : '2',
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none', // Remove border
            background: '#fff', // Background color of the modal
            padding: '20px', // Adjust padding as needed
            width: '300px' 
          },
        }}
      >
        <div>
          <form>
            <div className='calender-edit-header'>
              <div className='calender-edit'>
                Select the Start Date for User
              </div>
              <img 
                src={cross}
                alt="back"
                type="button" 
                style={{height:"20px"}}
                onClick={(e)=>setStartDateModel(false)}
              />
            </div>
            <div className='calender-edit-content'>
              <label className='calender-edit-label'>Start Date</label>
              <input
                className='calender-edit-input'
                type="date"
                name="start"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            
            <div className='AP-button3' type="button" onClick={handleSubmit}>
              <div className='inner-AP-button'>
                <div className='AP-text1'>SAVE</div>
              </div>
            </div>
            
          </form>
        </div>
      </Modal> */}

      <Modal
        isOpen={showErrorModal}
        onRequestClose={() => setShowErrorModal(false)}
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)',
            transition: 'background 0.5s ease',
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: '1px solid',
            borderColor: "#ac00004f",
            background: '#fff2f2',
            padding: '10px',
            width: '80%',
            borderRadius: "10px",
            color: "#9f0606",
            fontFamily: "Rajdhani",
            fontWeight: "500",
            fontSize: "110%",
          },
        }}
      >
        <div style={{
          display: "flex",
          alignItems: "center",
        }}>
          <img 
            src={error} 
            style={{
              height: "35px",
              marginRight: "10px",
            }}  
          />
          <div>Error adding patient data. Please try again later.</div>
        </div>
      </Modal>

            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> br
        <AddPatientSuccessPopUp showSuccessModal={showSuccessModal} setShowSuccessModal={setShowSuccessModal} patientData={PatientData} physioName={physioName}/>
    </div>
  )
}
