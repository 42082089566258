import React, { useState, useRef, useEffect } from 'react';
import logo from '../images/logo_page.png';
import { useParams, useNavigate } from 'react-router-dom';
import { useUserAuth } from "../context/UserAuthContext";
import { analytics } from "../firebase/index";
import { logEvent } from "firebase/analytics";

const PopUp = ({ message }) => {
  return (
    <div className="physio-not-registered-popup">
      <div className="physio-not-registered-popup-content">
        <p>{message}</p>
      </div>
    </div>
  );
};

const PhysioNewOTPPage = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [error, setError] = useState("");
  const { result, checkPhysioExist } = useUserAuth();
  const { countryCode, mobileNumber } = useParams();
  const [showWrongOTPPopup, setShowWrongOTPPopup] = useState(false);
  const [showTimeoutPopup, setshowTimeoutPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRefs = useRef([]);

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp.some(d => d === "") || otp.length !== 6) {
      setError("Please enter valid OTP.");
      return;
    }
    setIsLoading(true);
    try {
      const otpNumber = Number(otp.join(''));
      await result.confirm(otpNumber);
      logEvent(analytics, "py_verify_otp", {
        "Date": new Date().toLocaleString(),
        "encrypted_py_user_name": mobileNumber,
        "py_log": "ongoing",
      });
      const isRegistered = await checkPhysioExist(mobileNumber);
      if (isRegistered === 0) {
        navigate(`/onboarding/${countryCode}/${mobileNumber}`);
      } else {
        navigate("/");
      }
    } catch (err) {
      logEvent(analytics, "py_verify_otp_error", {
        "Date": new Date().toLocaleString(),
        "encrypted_py_user_name": mobileNumber,
        "py_log_error": err.message,
      });
      if (err.message === 'Firebase: Error (auth/invalid-verification-code).') {
        setError('Invalid OTP');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const closePopupOutside = (event) => {
      if (!event.target.closest(".physio-not-registered-popup-content")) {
        setShowWrongOTPPopup(false);
        setshowTimeoutPopup(false);
      }
    };

    if (showWrongOTPPopup || showTimeoutPopup) {
      document.addEventListener("click", closePopupOutside);
    } else {
      document.removeEventListener("click", closePopupOutside);
    }

    return () => {
      document.removeEventListener("click", closePopupOutside);
    };
  }, [showWrongOTPPopup, showTimeoutPopup]);

  const handleChange = (element, index) => {
    const value = element.value;
    if (!isNaN(value) && value.length <= 1) {
      setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);
      if (value !== "" && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      }
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData("text");
    if (paste.length === otp.length && /^[0-9]+$/.test(paste)) {
      const pasteArray = paste.split("").slice(0, otp.length);
      setOtp(pasteArray);
      pasteArray.forEach((value, index) => {
        inputRefs.current[index].value = value;
      });
      inputRefs.current[otp.length - 1].focus();
    }
  };

  const allDigitsEntered = otp.every(d => d !== "");

  return (
    <div className='newPhysioSignUp'>
      <div className='content'>
        <div className='textContainer'>
          <div style={{display:"flex"}}>
            <img src={logo} style={{height:"50px"}} alt="Logo" />
            <div style={{fontWeight:"600",fontSize:"40px"}}>
              Wizphys.io
            </div>
          </div>
          <div style={{paddingLeft:"3%", width:"180px", lineHeight:"18px"}}>
            Everything you need for a speedy recovery
          </div>
        </div>
        <div className="docImageWrapper">
          <img src='https://firebasestorage.googleapis.com/v0/b/snaiyu-7b192.appspot.com/o/PhysioUIContent%2Fdocimage.png?alt=media&token=18ab9702-e015-4f87-ba5b-8cba69026cc7' alt="OTP Doctor" className="docImage" loading="lazy" />
        </div>
      </div>
      <div className="inputSection">
        <div className="OTPinputContainer" style={{display: 'flex', justifyContent: 'space-between', paddingBottom:"24px"}} onPaste={handlePaste}>
          {otp.map((data, index) => (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              value={data}
              onChange={e => handleChange(e.target, index)}
              onKeyDown={e => handleKeyDown(e, index)}
              ref={el => (inputRefs.current[index] = el)}
              className="new-otp-input"
            />
          ))}
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom:"40px" }}>
          <label htmlFor="terms" style={{ color: '#2D2F35', fontWeight: "500" }}>
            {error ? (
              <span style={{ color: 'red', fontWeight:"bold", paddingRight:"8px"}}>
                {error} 
              </span>
            ) : (
              "Didn't get OTP? "
            )}
          </label>  <a style={{ color: "#E09C5C", fontWeight: "600", textDecoration:"underline" }}>Resend Now</a>
        </div>

        <button className='InputBtn' style={{ width: "100%", backgroundColor: allDigitsEntered ? '#3974E5' : '#8E8A8A' }} onClick={verifyOtp} disabled={!allDigitsEntered}>
          {isLoading ? 'Loading...' : 'GET STARTED'}
        </button>
      </div>

      {showWrongOTPPopup && <PopUp message="Wrong OTP. Please try again." />}
      {showTimeoutPopup && <PopUp message="OTP Expired." />}
    </div>
  );
}

export default PhysioNewOTPPage;
