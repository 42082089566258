import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import left from '../images/Left.png';
import { useUserAuth } from "../context/UserAuthContext";
import spinner from '../images/loaderr.gif';

export const QRcodePage = () => {
  const navigate = useNavigate();
  const { fetchPhysioData } = useUserAuth();

  const [message, setMessage] = useState('');
  const [QRcodelink, setQRcodeLink] = useState('');
  const [loading, setLoading] = useState(true); // For overall page loading
  const [imgLoading, setImgLoading] = useState(true); // For image loading

  useEffect(() => {
    const fetchQRCodeData = async () => {
      try {
        const data = await fetchPhysioData();
        console.log("this is physio Data ", data);
        setMessage(data.whatsAppMessage);
        setQRcodeLink(data.qrCodeURL);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false); // End page loader after fetching data
      }
    };

    fetchQRCodeData();
  }, []);

  const handleImageClick = () => {
    navigate('/profile');
  };

  const handleImageLoad = () => {
    setImgLoading(false); // Image has finished loading
  };

  return (
    <div className="qr-code-page">
      <div style={{ marginTop: "7%", marginBottom: "5%" }}>
        <img
          className='AP-back-image'
          src={left}
          alt="back"
          onClick={handleImageClick}
          style={{ marginLeft: "5%" }}
        />
        <span className='calender-text'>QR Code</span>
      </div>
      {loading ? (
        <div className="loader"></div> // Show page loader while fetching data
      ) : (
        <>
          <div className="qr-code-container" style={{ display: "flex", justifyContent: "center" }}>
            {imgLoading && (
              <img src={spinner} alt="Loading..." className='spinner-image' />
            )}
            <img
              className="qr-code-image"
              src={QRcodelink}
              alt="QR Code"
              style={{ display: imgLoading ? "hidden" : "block" }} // Hide QR code image while loading
              onLoad={handleImageLoad} // Trigger when the image finishes loading
            />
          </div>
          <div className="message-container">
            <div className="message-bubble">{message}</div>
          </div>
        </>
      )}
    </div>
  );
};
